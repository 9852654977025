<template>
  <v-container>
    <v-navigation-drawer
      v-model="showDetail"
      absolute
      temporary
      right
      :width="showDetail ? 550 : 0"
      @input="handleInput"
    >
      <v-card flat>
        <v-card-title>
          <v-toolbar dense>
            <v-toolbar-title> Historique du Document </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="audit"
              :loading="snackbar"
              v-if="$store.state.isadmin"
            >
              <v-icon left> mdi-magnify-scan </v-icon>Audit</v-btn
            >
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-timeline align-top dense>
            <v-timeline-item
              v-for="(item, i) in operations"
              :key="i"
              fill-dot
              small
              :color="item.color"
              :icon="item.icon"
            >
              <strong>{{ item.label }}</strong>
              {{
                " Par " +
                item.user_name +
                " le " +
                datefr(item.created_at, true)
              }}
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>
<script>
import { datefr } from "@/utils/functions.js";
import AUDIT from "../graphql/AUDIT.gql";
export default {
  props: {
    operations: Array,
    value: Boolean,
    document_type: Number,
    document_id: Number,
    doc_type: String,
    doc_code: String,
  },
  watch: {
    value: {
      async handler() {
        if (this.value) this.showDetail = true;
        else this.showDetail = false;
      },
    },
  },
  data() {
    return {
      snackbar: false,
      snackbar_timeout: 2000,
      snackbar_text: "",
      snackbar_color: "primary",
      showDetail: false,
    };
  },
  created() {},
  computed: {
    datefr() {
      return datefr;
    },
  },
  methods: {
    handleInput() {
      this.$emit("input", this.showDetail);
    },
    async requette(query, v) {
      let r;
      this.loading = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
          this.loading = false;
        });
      return r;
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
    async audit() {
      this.downloadLoading = true;
      this.snackbar_text = "Chargement données";
      this.snackbar_color = "primary";
      this.snackbar = true;
      let r = await this.requette(AUDIT, {
        document_type: this.document_type,
        document_id: this.document_id,
      });
      this.downloadLoading = false;
      this.snackbar_text = "Chargement terminé";
      this.snackbar_color = "success";
      this.snackbar = true;
      if (r) {
        let headers = [
          { text: "Date", value: "created_at" },
          { text: "Operateur", value: "name" },
          { text: "Evenement", value: "event" },
          { text: "Avant", value: "old_values" },
          { text: "Aprés", value: "new_values" },
          { text: "Url", value: "url" },
          { text: "Adrese IP", value: "ip_address" },
          { text: "Navigateur", value: "user_agent" },
        ];
        if (r.audits_document.length > 0) {
          import("@/utils/export").then((excel) => {
            const tHeader = headers.map((elm) => elm.text);
            const filterVal = headers.map((elm) => elm.value);
            const data = this.formatJson(filterVal, r.audits_document);
            excel.export_json_to_excel({
              header: tHeader,
              data,
              filename: "Audits_" + this.doc_type + "_" + this.doc_code,
              autoWidth: true,
              bookType: "xlsx",
            });
          });
        }
      }
    },
  },
};
</script>
